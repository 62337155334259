.table-wrapper {
  padding: 10px;
}

.-header {
  color: black !important;
  box-shadow: none !important;
  font-size: large;
  text-transform: capitalize;
}

.-btn {
  background-color: #17a2b8 !important;
}

.-next, .-previous {
  flex: 0.3 1 !important;
}

.rt-noData {
  top: 100% !important;
  transform: translate(-50%, 0%) !important;
}

.for_size {
  padding: 0px 20px !important;
  margin: 0px 5px
}

.rt-td {
  padding: 2px 5px !important;
}